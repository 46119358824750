
#root, .ant-layout-content {
    width: 100%;
    height: 100%;

    min-width: 600px;
    min-height: 900px;
}

.ant-layout-header,
.ant-layout-footer {
    color: #ddd;
    background-color: rgba(0, 21, 41, 0.5);
}

.ant-layout-footer {
    padding: 3px;
}

.ant-layout-footer a {
    color: #ddd;
}

.ant-layout-footer a:visited {
    color: #ddd;
}

.ant-layout-footer a:hover {
    text-decoration: underline;
}

.layout-box {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
    height: 100%;
    min-width: 1440px;
    min-height: 900px;
}

.content {
    width: 100%;
    height: 100%;

    justify-content:center;
    align-items:center;
    display:-webkit-flex;
}

.background-box {
    width: 100%;
    height: auto;
}

.cells-box {
    /*background-color: #FF0000;*/
    /*opacity: 0.2;*/
    position: absolute;
}

.cell {
    background-color: #FFFFFF;
    opacity: 0.2;

    position: absolute;
    border: 0;
    border-radius: 8px;
}

.cell:hover {
    opacity: 0.5;
}

.tile {
    background-color: #FFFFFF;
    opacity: 0.7;
    text-align: center;
    vertical-align: bottom;

    position: absolute;
    border: 0;
    border-radius: 8px;

    justify-content:center;
    align-items:center;
    display:-webkit-flex;
}

.tile:hover {
    opacity: 0.9;
}

.tile-title {
    font-size: 12px;
    color: #000000;
    background-color: #FFFFFF;
    opacity: 0.5;
    width: 100%;
    position:absolute;
    bottom:0px;
    left:0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.tile-icon {
    max-width: 100%;
    max-height: 100%;

}

.search-tile {
    background-color: #FFFFFF;
    position: absolute;
    border: 0;
    border-radius: 8px;
    opacity: 0.8;
}

.search-tile:focus {
    opacity: 1;
}

.search-box {
    padding: 0 15px 0 15px;
    text-align: center;
}

.search-logo {
    min-width: 150px;
    min-height: 60px;
    max-width: 150px;
    max-height: 60px;
    margin: 0 auto 10px auto;
}

